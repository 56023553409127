<template>
  <ui-component-modal
    modalTitle="Combine spaces"
    :isSaving="isSaving"
    :onClickCancel="onClickCancel"
    :onClickSave="combineSelectedSpaces"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template v-slot:content>
      <table class="table is-fullwidth is-striped is-narrow">
        <tbody>
          <tr v-for="space in spaces" :key="space.Id">
            <td>
              <label class="checkbox">
                <input type="checkbox" :value="space.Id" v-model="spaceIds" />
                {{ space.Name }}
              </label>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </ui-component-modal>
</template>

<script>
import spaceProvider from "@/providers/space";

export default {
  props: {
    locationId: {
      default: 0,
      type: Number
    },

    spaces: {
      default: function() {
        return [];
      },
      type: Array
    },

    showModal: {
      type: Boolean,
      default: false
    },

    onClickCancel: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      isSaving: false,
      spaceIds: []
    };
  },

  methods: {
    combineSelectedSpaces() {
      if (this.spaceIds.length > 0) {
        this.isSaving = true;
        spaceProvider.methods
          .combineSpaces(this.locationId, this.spaceIds)
          .then(response => {
            if (response.status === 200) {
              this.$emit("dataSaved", response.data);
              this.onClickCancel();
            }
          })
          .finally(() => {
            this.isSaving = false;
          });
      }
    }
  }
};
</script>

<style></style>
